import * as React from "react"
import PageLayout from "../../components/pageLayout";
import  {Table ,Form , Input , Button, Row, Col , Select} from "antd";
import firebase from "firebase";
import { firebaseInit } from "../../firebaseConfig";
import {Link} from "gatsby"

const {Option} = Select;
const  CatalogSearchPage = () => {

    
    React.useEffect(()=> {

         firebaseInit(firebase);

         firebase.firestore().collection("products").get().then((querySnapshot)=>{

            let products  = querySnapshot.docs.map(doc => doc.data())            
            setProducts(products)            
         })
    },[])

    const {Column} = Table
    
    const [products, setProducts ] = React.useState([]);
    const [brand, setBrand] = React.useState("all")
    const [id, setId] = React.useState("")
    const [model, setModel] = React.useState("")    
    
    const filteredProducts =  React.useMemo(()=>{                
        return products.filter(item => {

            const idPredicate = function(sku) {         
                if(sku)
                    return item.sku === sku
                else 
                    return true
            }
            const modelPredicate = function (model) {
                if(model)
                    return item.model === model
                else 
                    return true 
            }
            const brandPredicate = function(brand) {
                if(brand)
                    return item.brand === brand || item.supplier === brand || brand === "all"
                else    
                    return true     
            }            
            return idPredicate(id) && modelPredicate(model) && brandPredicate(brand)
            
        })        
    },[brand, id, model])
    

    const availableBrands = React.useMemo(()=>{   
        console.log("brand computed")    
        if(products)
        {
            let brands = products.map(product => {
                let brand =  product.brand ?? product.supplier
                return brand ?? "Not found"
            })
            return [...new Set(brands)].sort()
        }
           return [];
    }, [products])

    const handleBrandSelection = (brand) => {
        setBrand(brand)
    }
    const onIdChange = (e) => {
        setId(e.target.value)
    }
    const onModelChange = (e) => {
        setModel(e.target.value)
    }

    const renderImage = (src) => {

      return (<div className="w-10"><img src={src}></img></div>)
    }

    const skuSorter = (a,b)=>a.sku-b.sku

    return (<PageLayout>

     <Form layout="vertical">
         <Row gutter={8}>
             <Col span={6}>
                <Form.Item label="Id">
                     <Input placeholder="Id del producto" value={id} onChange={onIdChange}></Input>
                </Form.Item>
             </Col>
             <Col span={6}>
                 <Form.Item label="Marca">
                    <Select defaultValue="all" onSelect={handleBrandSelection} showSearch 
                        filterOption={(input, option) =>{
                                if(option)
                                    return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}

                          filterSort={(optionA, optionB) =>{
                            if(optionA && optionB)
                                return optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())}
                          }
                    >
                        <Option  key="default" value="all">Todas las marcas</Option>
                        {availableBrands.map( brand => <Option key={brand} value={brand}>{brand}</Option>)} 
                    </Select>
                </Form.Item>            
             </Col>             
             <Col span={6}>
                 <Form.Item label="Modelo">
                    <Input placeholder="Modelo" value={model} onChange={onModelChange}></Input>
                </Form.Item>            
             </Col>             
             <Col span={6}>
                 <Form.Item label=" ">
                    <Button type="primary">Buscar</Button>
                </Form.Item>            
             </Col>
         </Row>                                    
     </Form>


        <Table dataSource={filteredProducts}>
          <Column title="" dataIndex="thumbail" render={renderImage}></Column>
          <Column title="Id" dataIndex="sku" sorter={skuSorter}  render={value=><Link to={`/catalog/${value}`}>{value}</Link>}></Column>
          <Column title="Nombre" dataIndex="title"></Column>
          <Column title="Marca" dataIndex="brand"></Column>
          <Column title="Proveedor" dataIndex="supplier" ></Column>
          <Column title="Modelo" dataIndex="model"></Column>          
          <Column title="Precio base" dataIndex="price"></Column>
          <Column title="Precio sugerido" dataIndex="retailPrice"></Column>
          <Column title="Departamento" dataIndex="department"></Column>
          <Column title="Categoría" dataIndex="category"></Column>
          <Column title="Subcategoría" dataIndex="subcategory"></Column>
        </Table>
    </PageLayout>)
} 

export default CatalogSearchPage;